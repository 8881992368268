<template>
  <div>
    <VTitle :title="section.title" class="overview-title" />

    <VCard>
      <template #content>
        <DonutChart
          :labels="[$t('app.Active'), $t('app.Inactive')]"
          :chart-data="[section.active, section.inactive]"
          :background-color="colors"
          :hover-background-color="colors"
        />

        <div class="grid gap-y-4">
          <div class="flex justify-between">
            <div class="flex">
              <div class="self-center rounded-full bg-green-400 w-3 h-3 mr-2" />
              {{ $t("app.Active") }}
            </div>

            <div class="font-medium">
              {{ formatAmount(section?.active ?? 0, 0) }}
            </div>
          </div>

          <div class="flex justify-between">
            <div class="flex">
              <div class="self-center rounded-full bg-red-400 w-3 h-3 mr-2" />
              {{ $t("app.Inactive") }}
            </div>

            <div class="font-medium">
              {{ formatAmount(section?.inactive ?? 0, 0) }}
            </div>
          </div>
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import DonutChart from "@/components/charts/donut-chart/Main";
import VCard from "@/components/VCard";
import VTitle from "@/components/VTitle";

export default {
  components: {
    DonutChart,
    VCard,
    VTitle
  },
  props: {
    section: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    // CONSTANTS
    const activeColor = "#69cf9d";
    const inactiveColor = "#e87975";
    const colors = [activeColor, inactiveColor];

    // Composables
    const { formatAmount } = useDisplay();

    return {
      colors,
      formatAmount
    };
  }
};
</script>
