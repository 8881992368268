<template>
  <VCard>
    <template #content>
      <div>
        <div class="text-lg font-medium">
          {{ license?.name || "" }}
        </div>

        <div>
          {{ dates }}
        </div>
      </div>

      <PieChart
        :labels="[$t('app.assigned'), $t('app.unassigned')]"
        :chart-data="[license.assigned_active_users, unassignedUsersPie]"
        :background-color="colors"
        :hover-background-color="colors"
      />

      <div class="grid gap-y-4">
        <div class="flex justify-between">
          <div class="flex">
            <div class="self-center rounded-full bg-blue-400 w-3 h-3 mr-2" />
            {{ $t("app.assigned") }}
          </div>

          <div class="font-medium">
            {{ activeUsers }}
          </div>
        </div>

        <div class="flex justify-between">
          <div class="flex">
            <div class="self-center rounded-full bg-yellow-400 w-3 h-3 mr-2" />
            {{ $t("app.unassigned") }}
          </div>

          <div class="font-medium">
            {{ unassignedUsers }}
          </div>
        </div>
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import PieChart from "@/components/charts/pie-chart/Main";
import VCard from "@/components/VCard";

export default {
  components: {
    PieChart,
    VCard
  },
  props: {
    license: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();

    const { formatAmount, formatDate } = useDisplay();

    // CONSTANTS
    const assignedColor = "#6fa5f3";
    const unassignedColor = "#f2c04b";
    const colors = [assignedColor, unassignedColor];

    // COMPUTED
    const unassignedUsersPie = computed(() => {
      const { number_of_users, assigned_active_users } = props.license;

      if (!number_of_users || !assigned_active_users) {
        return 0;
      }

      return parseInt(number_of_users) - parseInt(assigned_active_users);
    });

    const unassignedUsers = computed(() => {
      const { number_of_users, assigned_active_users } = props.license;

      if (!number_of_users || !assigned_active_users) {
        return 0;
      }

      return formatAmount(
        parseInt(number_of_users) - parseInt(assigned_active_users),
        0
      );
    });

    const activeUsers = computed(() => {
      const activeUsers = props.license?.assigned_active_users || 0;
      return formatAmount(activeUsers, 0);
    });

    const dates = computed(() => {
      const start = formatDate({ date: props.license?.start_date });
      const end = formatDate({
        date: props.license?.expiry_date,
        fallback: ""
      });

      if (start && !end) {
        return `${start} ${t("app.to")} ${t("app.perpetuity")}`;
      }

      if (!start || !end) {
        return "";
      }

      return `${start} ${t("app.to")} ${end}`;
    });

    return {
      unassignedUsers,
      unassignedUsersPie,
      dates,
      colors,
      activeUsers
    };
  }
};
</script>
