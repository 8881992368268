<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.dashboard')" class="route-title" />

      <Tippy tag="a" class="tooltip" :content="$t('app.refresh_content')">
        <RefreshCcwIcon
          class="text-theme-1 cursor-pointer"
          @click="refreshData"
        />
      </Tippy>
    </div>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else>
      <div v-if="learner || instructor" class="grid grid-cols-3 gap-8">
        <div class="col-span-3 2xl:col-span-2 mb-8">
          <div
            class="grid mb-8"
            :class="{ 'grid-cols-2 gap-4': instructor && learner }"
          >
            <InstructorSummary
              v-if="instructor"
              :summary="instructor?.summary"
              :class="{ 'col-span-2 2xl:col-span-1': instructor && learner }"
            >
              <template #title>
                <VTitle
                  :title="$t('app.instructor_summary')"
                  class="overview-title"
                />
              </template>
            </InstructorSummary>

            <LearnerSummary
              v-if="learner"
              :summary="learner?.summary"
              :class="{ 'col-span-2 2xl:col-span-1': instructor && learner }"
            >
              <template #title>
                <VTitle
                  :title="$t('app.learner_summary')"
                  class="overview-title"
                />
              </template>
            </LearnerSummary>
          </div>

          <div v-if="learner" class="grid grid-cols-2 gap-4">
            <VStatisticsCard
              :title="$t('app.my_competency_statuses', 2)"
              :statistics="learner?.competency_statuses"
            />

            <RegistrationsToComplete :data="learner?.registrations_to_complete">
              <VTitle
                :title="$t('app.my_registrations_to_complete')"
                class="overview-title"
              />
            </RegistrationsToComplete>
          </div>
        </div>

        <div class="col-span-3 2xl:col-span-1">
          <div class="grid gap-8">
            <LearningScore
              v-if="learner"
              :learning-scores="learner?.learning_scores"
            />

            <ScheduledObservations :events="observations">
              <div v-if="observations.length" class="pt-5">
                <div v-if="observationsThisMonth.length">
                  <div class="text-center">
                    {{ $t("app.as_instructor") }}
                  </div>

                  <div
                    v-for="(item, index) in observationsThisMonth"
                    :key="index"
                    class="flex items-center"
                    :class="{ 'mt-4': index !== 0 }"
                  >
                    <div class="w-2 h-2 bg-theme-21 rounded-full mr-3" />

                    <span class="truncate">
                      {{ getText(item?.evaluation?.texts, "name") }}
                    </span>

                    <div
                      class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                    />

                    <span class="font-medium xl:ml-auto">
                      {{ formatDate({ date: item?.observation_scheduled_on }) }}
                    </span>
                  </div>
                </div>

                <div v-if="scheduledObservations.length">
                  <div class="text-center">
                    {{ $t("app.as_learner") }}
                  </div>

                  <div
                    v-for="(item, index) in observationsThisMonth"
                    :key="index"
                    class="flex items-center"
                    :class="{ 'mt-4': index !== 0 }"
                  >
                    <div class="w-2 h-2 bg-theme-21 rounded-full mr-3" />

                    <span class="truncate">
                      {{ getText(item?.evaluation?.texts, "name") }}
                    </span>

                    <div
                      class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                    />

                    <span class="font-medium xl:ml-auto">
                      {{ formatDate({ date: item?.observation_scheduled_on }) }}
                    </span>
                  </div>
                </div>
              </div>
            </ScheduledObservations>
          </div>
        </div>
      </div>

      <div v-if="instructor" class="mb-8">
        <VTitle
          :title="$t('app.teaching_statuses', 2)"
          class="overview-title"
        />

        <div class="grid grid-cols-12 gap-4 mt-4">
          <Status
            v-for="(value, key, index) in instructor.statuses"
            :key="index"
            :chart-type="chartTypes.DONUT"
            :title="$t(`backend.${key}`)"
            :status="value"
            class="col-span-12 sm:col-span-12 lg:col-span-6 2xl:col-span-4"
          />
        </div>
      </div>

      <div v-if="administrator" class="grid grid-cols-3 gap-8">
        <div class="col-span-3 2xl:col-span-2 mb-8">
          <SectionOverview :sections="sections" />
        </div>

        <div class="col-span-3 2xl:col-span-1">
          <VTitle :title="$t('app.licenses', 2)" class="overview-title" />

          <div class="grid gap-4">
            <License
              v-for="(license, index) in licenses"
              :key="`license-${index}`"
              :license="license"
              class="mb-5"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import License from "@/views/administration/dashboard/License";
import SectionOverview from "@/views/administration/dashboard/SectionOverview";
import InstructorSummary from "@/views/teaching/dashboard/Summary";
import Status from "@/views/teaching/dashboard/Status";
import LearnerSummary from "@/views/learning/dashboard/Summary";
import LearningScore from "@/views/learning/dashboard/LearningScore";
import ScheduledObservations from "@/views/learning/dashboard/ScheduledObservations";
import RegistrationsToComplete from "@/views/learning/dashboard/RegistrationsToComplete";
import VStatisticsCard from "@/components/VStatisticsCard";
// Constants
import chartTypes from "@/constants/chartTypes";

export default {
  components: {
    VTitle,
    License,
    LearningScore,
    Status,
    SectionOverview,
    InstructorSummary,
    VStatisticsCard,
    ScheduledObservations,
    LearnerSummary,
    RegistrationsToComplete
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Computed
    // eslint-disable-next-line
    const documentTitle = computed(() => `${t("app.dashboard")} - ${t("app.home")}`);
    const administrator = computed(() => data.value?.administrator);
    const sections = computed(() => [
      {
        title: t("app.sites", 2),
        ...(administrator.value?.sites ?? {})
      },
      {
        title: t("app.departments", 2),
        ...(administrator.value?.departments ?? {})
      },
      {
        title: t("app.roles", 2),
        ...(administrator.value?.roles ?? {})
      },
      {
        title: t("app.users", 2),
        ...(administrator.value?.users ?? {})
      }
    ]);
    const licenses = computed(() => administrator.value?.licenses);
    const instructor = computed(() => data.value?.instructor);
    const learner = computed(() => data.value?.learner);
    // eslint-disable-next-line
    const scheduledObservations = computed(() => learner.value?.scheduled_observations ?? []);
    // eslint-disable-next-line
    const observationsThisMonth = computed(() => instructor.value?.observations_this_month ?? []);
    const observations = computed(() => [
      ...scheduledObservations.value,
      ...observationsThisMonth.value
    ]);

    // Composables
    const { request } = useRequest();
    const { formatDate } = useDisplay();
    const { getText } = useTexts();

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "platform.dashboard"
      });

      data.value = response?.payload?.data ?? {};
      isLoading.value = false;
    };

    const refreshData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "platform.dashboard.refresh"
      });

      data.value = response?.payload?.data ?? {};
      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      formatDate,
      documentTitle,
      administrator,
      sections,
      licenses,
      instructor,
      learner,
      refreshData,
      data,
      isLoading,
      chartTypes,
      observations,
      observationsThisMonth,
      scheduledObservations,
      // useTexts
      getText
    };
  }
};
</script>
