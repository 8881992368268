<template>
  <VCard>
    <template #content>
      <div class="flex items-start">
        <div>
          <div class="uppercase text-gray-600 mt-1">
            {{ title }}
          </div>

          <div class="text-lg font-medium truncate">
            {{ total }}
          </div>
        </div>

        <div v-if="hasValues" class="ml-auto relative">
          <component
            :is="getComponent(chartType)"
            :height="100"
            :width="100"
            :labels="labels"
            :chart-data="chartData"
            :background-color="colors"
            :hover-background-color="colors"
            class="relative"
          />
        </div>

        <div v-else class="ml-auto w-24">
          <div class="circle-stats" />
        </div>
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
// Components
import DonutChart from "@/components/charts/donut-chart/Main";
import PieChart from "@/components/charts/pie-chart/Main";
import VCard from "@/components/VCard";
// Constants
import chartTypes from "@/constants/chartTypes";

export default {
  components: {
    PieChart,
    DonutChart,
    VCard
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    status: {
      type: Object,
      default: () => ({})
    },
    chartType: {
      type: String,
      default: chartTypes.PIE,
      validator: value => Object.values(chartTypes).includes(value)
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { formatAmount } = useDisplay();
    const { getRGBStatusColor } = useColor();

    // Computed
    const keys = computed(() => Object.keys(props.status));
    const values = computed(() => Object.values(props.status));
    // eslint-disable-next-line
    const colors = computed(() => keys.value.map(key => getRGBStatusColor(key)));
    // eslint-disable-next-line
    const chartData = computed(() => values.value.map(value => formatAmount(value, 0)));
    const labels = computed(() => keys.value.map(key => t(`backend.${key}`)));
    const total = computed(() => {
      const total = values.value.reduce((acc, cv) => acc + parseInt(cv), 0);

      return formatAmount(total, 0);
    });
    const hasValues = computed(() => values.value.some(v => v));

    // Methods
    const getComponent = chartType => {
      const components = {
        [chartTypes.PIE]: "PieChart",
        [chartTypes.DONUT]: "DonutChart"
      };

      return components[chartType];
    };

    return {
      values,
      getComponent,
      total,
      colors,
      chartData,
      labels,
      hasValues
    };
  }
};
</script>

<style scoped>
.circle-stats {
  border: solid 5px rgba(247, 250, 252, 1);
  border-radius: 50%;
  width: 100%;
  padding-bottom: 100%;
  background: white;
}
</style>
