<template>
  <div class="grid grid-cols-12 gap-6">
    <Section
      v-for="(section, index) in sections"
      :key="`section-${index}`"
      :section="section"
      class="col-span-12 sm:col-span-6 xl:col-span-3"
    />
  </div>
</template>

<script>
import Section from "./Section";

export default {
  components: {
    Section
  },
  props: {
    sections: {
      type: Array,
      default: () => []
    }
  }
};
</script>
